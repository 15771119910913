.currency-flag {
  display: inline-block;
  width: 24px;
  height: 16px;
  background-size: cover;
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle_fallback.png);
}
.currency-flag-sm {
  width: 16px;
  height: 10px;
}
.currency-flag-lg {
  width: 36px;
  height: 24px;
}
.currency-flag-xl {
  width: 48px;
  height: 32px;
}
.currency-flag.currency-flag-aed {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/aed.png);
}
.currency-flag.currency-flag-afn {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/afn.png);
}
.currency-flag.currency-flag-all {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/all.png);
}
.currency-flag.currency-flag-amd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/amd.png);
}
.currency-flag.currency-flag-ang {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/ang.png);
}
.currency-flag.currency-flag-aoa {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/aoa.png);
}
.currency-flag.currency-flag-ars {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/ars.png);
}
.currency-flag.currency-flag-aud {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/aud.png);
}
.currency-flag.currency-flag-awg {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/awg.png);
}
.currency-flag.currency-flag-azn {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/azn.png);
}
.currency-flag.currency-flag-bam {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bam.png);
}
.currency-flag.currency-flag-bbd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bbd.png);
}
.currency-flag.currency-flag-bdt {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bdt.png);
}
.currency-flag.currency-flag-bgn {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bgn.png);
}
.currency-flag.currency-flag-bhd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bhd.png);
}
.currency-flag.currency-flag-bif {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bif.png);
}
.currency-flag.currency-flag-bmd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bmd.png);
}
.currency-flag.currency-flag-bnd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bnd.png);
}
.currency-flag.currency-flag-bob {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bob.png);
}
.currency-flag.currency-flag-brl {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/brl.png);
}
.currency-flag.currency-flag-bsd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bsd.png);
}
.currency-flag.currency-flag-btn {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/btn.png);
}
.currency-flag.currency-flag-bwp {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bwp.png);
}
.currency-flag.currency-flag-byn {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/byn.png);
}
.currency-flag.currency-flag-bzd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/bzd.png);
}
.currency-flag.currency-flag-cad {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/cad.png);
}
.currency-flag.currency-flag-cdf {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/cdf.png);
}
.currency-flag.currency-flag-chf {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/chf.png);
}
.currency-flag.currency-flag-clp {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/clp.png);
}
.currency-flag.currency-flag-cny {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/cny.png);
}
.currency-flag.currency-flag-cop {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/cop.png);
}
.currency-flag.currency-flag-crc {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/crc.png);
}
.currency-flag.currency-flag-cup {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/cup.png);
}
.currency-flag.currency-flag-cve {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/cve.png);
}
.currency-flag.currency-flag-czk {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/czk.png);
}
.currency-flag.currency-flag-djf {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/djf.png);
}
.currency-flag.currency-flag-dkk {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/dkk.png);
}
.currency-flag.currency-flag-dop {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/dop.png);
}
.currency-flag.currency-flag-dzd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/dzd.png);
}
.currency-flag.currency-flag-egp {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/egp.png);
}
.currency-flag.currency-flag-ern {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/ern.png);
}
.currency-flag.currency-flag-etb {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/etb.png);
}
.currency-flag.currency-flag-eur {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/eur.png);
}
.currency-flag.currency-flag-fjd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/fjd.png);
}
.currency-flag.currency-flag-fkp {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/fkp.png);
}
.currency-flag.currency-flag-gbp {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/gbp.png);
}
.currency-flag.currency-flag-gel {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/gel.png);
}
.currency-flag.currency-flag-ghs {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/ghs.png);
}
.currency-flag.currency-flag-gip {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/gip.png);
}
.currency-flag.currency-flag-gmd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/gmd.png);
}
.currency-flag.currency-flag-gnf {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/gnf.png);
}
.currency-flag.currency-flag-gtq {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/gtq.png);
}
.currency-flag.currency-flag-gyd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/gyd.png);
}
.currency-flag.currency-flag-hkd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/hkd.png);
}
.currency-flag.currency-flag-hnl {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/hnl.png);
}
.currency-flag.currency-flag-hrk {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/hrk.png);
}
.currency-flag.currency-flag-htg {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/htg.png);
}
.currency-flag.currency-flag-huf {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/huf.png);
}
.currency-flag.currency-flag-idr {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/idr.png);
}
.currency-flag.currency-flag-ils {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/ils.png);
}
.currency-flag.currency-flag-inr {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/inr.png);
}
.currency-flag.currency-flag-iqd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/iqd.png);
}
.currency-flag.currency-flag-irr {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/irr.png);
}
.currency-flag.currency-flag-isk {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/isk.png);
}
.currency-flag.currency-flag-jmd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/jmd.png);
}
.currency-flag.currency-flag-jod {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/jod.png);
}
.currency-flag.currency-flag-jpy {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/jpy.png);
}
.currency-flag.currency-flag-kes {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/kes.png);
}
.currency-flag.currency-flag-kgs {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/kgs.png);
}
.currency-flag.currency-flag-khr {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/khr.png);
}
.currency-flag.currency-flag-kmf {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/kmf.png);
}
.currency-flag.currency-flag-kpw {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/kpw.png);
}
.currency-flag.currency-flag-krw {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/krw.png);
}
.currency-flag.currency-flag-kwd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/kwd.png);
}
.currency-flag.currency-flag-kyd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/kyd.png);
}
.currency-flag.currency-flag-kzt {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/kzt.png);
}
.currency-flag.currency-flag-lak {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/lak.png);
}
.currency-flag.currency-flag-lbp {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/lbp.png);
}
.currency-flag.currency-flag-lkr {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/lkr.png);
}
.currency-flag.currency-flag-lrd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/lrd.png);
}
.currency-flag.currency-flag-ltl {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/ltl.png);
}
.currency-flag.currency-flag-lyd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/lyd.png);
}
.currency-flag.currency-flag-mad {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mad.png);
}
.currency-flag.currency-flag-mdl {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mdl.png);
}
.currency-flag.currency-flag-mga {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mga.png);
}
.currency-flag.currency-flag-mkd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mkd.png);
}
.currency-flag.currency-flag-mmk {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mmk.png);
}
.currency-flag.currency-flag-mnt {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mnt.png);
}
.currency-flag.currency-flag-mop {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mop.png);
}
.currency-flag.currency-flag-mro {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mro.png);
}
.currency-flag.currency-flag-mur {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mur.png);
}
.currency-flag.currency-flag-mvr {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mvr.png);
}
.currency-flag.currency-flag-mwk {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mwk.png);
}
.currency-flag.currency-flag-mxn {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mxn.png);
}
.currency-flag.currency-flag-myr {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/myr.png);
}
.currency-flag.currency-flag-mzn {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/mzn.png);
}
.currency-flag.currency-flag-nad {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/nad.png);
}
.currency-flag.currency-flag-ngn {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/ngn.png);
}
.currency-flag.currency-flag-nio {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/nio.png);
}
.currency-flag.currency-flag-nok {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/nok.png);
}
.currency-flag.currency-flag-npr {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/npr.png);
}
.currency-flag.currency-flag-nzd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/nzd.png);
}
.currency-flag.currency-flag-omr {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/omr.png);
}
.currency-flag.currency-flag-pen {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/pen.png);
}
.currency-flag.currency-flag-pgk {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/pgk.png);
}
.currency-flag.currency-flag-php {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/php.png);
}
.currency-flag.currency-flag-pkr {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/pkr.png);
}
.currency-flag.currency-flag-pln {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/pln.png);
}
.currency-flag.currency-flag-pyg {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/pyg.png);
}
.currency-flag.currency-flag-qar {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/qar.png);
}
.currency-flag.currency-flag-ron {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/ron.png);
}
.currency-flag.currency-flag-rsd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/rsd.png);
}
.currency-flag.currency-flag-rub {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/rub.png);
}
.currency-flag.currency-flag-rwf {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/rwf.png);
}
.currency-flag.currency-flag-sar {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/sar.png);
}
.currency-flag.currency-flag-sbd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/sbd.png);
}
.currency-flag.currency-flag-scr {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/scr.png);
}
.currency-flag.currency-flag-sek {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/sek.png);
}
.currency-flag.currency-flag-sgd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/sgd.png);
}
.currency-flag.currency-flag-shp {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/shp.png);
}
.currency-flag.currency-flag-sll {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/sll.png);
}
.currency-flag.currency-flag-sos {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/sos.png);
}
.currency-flag.currency-flag-srd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/srd.png);
}
.currency-flag.currency-flag-std {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/std.png);
}
.currency-flag.currency-flag-svc {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/svc.png);
}
.currency-flag.currency-flag-syp {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/syp.png);
}
.currency-flag.currency-flag-szl {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/szl.png);
}
.currency-flag.currency-flag-thb {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/thb.png);
}
.currency-flag.currency-flag-tjs {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/tjs.png);
}
.currency-flag.currency-flag-tnd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/tnd.png);
}
.currency-flag.currency-flag-top {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/top.png);
}
.currency-flag.currency-flag-try {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/try.png);
}
.currency-flag.currency-flag-ttd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/ttd.png);
}
.currency-flag.currency-flag-twd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/twd.png);
}
.currency-flag.currency-flag-tzs {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/tzs.png);
}
.currency-flag.currency-flag-uah {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/uah.png);
}
.currency-flag.currency-flag-ugx {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/ugx.png);
}
.currency-flag.currency-flag-usd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/usd.png);
}
.currency-flag.currency-flag-uyu {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/uyu.png);
}
.currency-flag.currency-flag-uzs {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/uzs.png);
}
.currency-flag.currency-flag-vef {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/vef.png);
}
.currency-flag.currency-flag-vnd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/vnd.png);
}
.currency-flag.currency-flag-vuv {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/vuv.png);
}
.currency-flag.currency-flag-wst {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/wst.png);
}
.currency-flag.currency-flag-xaf {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/xaf.png);
}
.currency-flag.currency-flag-xcd {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/xcd.png);
}
.currency-flag.currency-flag-xof {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/xof.png);
}
.currency-flag.currency-flag-xpf {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/xpf.png);
}
.currency-flag.currency-flag-yer {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/yer.png);
}
.currency-flag.currency-flag-zar {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/zar.png);
}
.currency-flag.currency-flag-zmw {
  background-image: url(https://wise.com/public-resources/assets/flags/rectangle/zmw.png);
}
